import React from "react"
import { Navbar } from "react-bootstrap"
import siteBrandDark from '../../../assets/image/logo/commerce_solutions_logo.inline.svg'
import siteBrandLight from '../../../assets/image/logo/commerce_solutions_logo_dark.inline.svg'
import Menu from "./Menu"
const SiteNavbar = ({buttonBlock,darkLogo,customLogo,defaultLogo}) => {

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
      >
        <Navbar.Brand href="/">

          {defaultLogo ? (<img src={defaultLogo} alt="site-brand"/>) : customLogo ? (
            <img src={customLogo} alt="Commerce Solutions" className="header-logo" title="Commerce Solutions" />
          ) : (
            <img
            src={darkLogo ? siteBrandDark : siteBrandLight} alt="Commerce Solutions" className="header-logo" title="Commerce Solutions" />
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
        />
        <Menu/>
        {/* {buttonBlock} */}
      </Navbar>
    </>
  )
}

export default SiteNavbar
