import React from "react";
import { Helmet } from "react-helmet";
import favicon from '~image/favicon.ico';
import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
    <Helmet>
          <title>Commerce Solutions - Composable Commerce Experts</title>
          <link rel="icon" type="image/png" href={favicon} />
          <meta content="Commerce Solutions is a consultancy that designs, implements, and optimizes enterprise commerce, content, subscription, and digital replatforming. Talk to an expert." name="description" />
          <meta content="Commerce Solutions - Composable Commerce Experts" property="og:title" />
          <meta content="Commerce Solutions is a consultancy that designs, implements, and optimizes enterprise commerce, content, subscription, and digital replatforming. Talk to an expert.." property="og:description" />
          <meta content="https://cdn.commercesolutions.io/commerce_solutions_twitter_banner.jpg" property="og:image" />
          <meta content="Commerce Solutions - Composable Commerce Experts" property="twitter:title" />
          <meta content="Commerce Solutions is a consultancy that designs, implements, and optimizes enterprise commerce, content, subscription, and digital replatforming. Talk to an expert." property="twitter:description" />
          <meta content="https://cdn.commercesolutions.io/commerce_solutions_twitter_banner.jpg" property="twitter:image" />
          <meta property="og:type" content="website" />
    </Helmet>
      <Header/>
      {children}
    </>
  )
}
